import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectGrid from '../components/ProjectGrid'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/cozinha-28-n/header-right-banner.jpg'
import projecPhoto1 from '../images/projects/cozinha-28-n/apt-template-1.jpg'
import projecPhoto2 from '../images/projects/cozinha-28-n/apt-template-2.jpg'
import projecPhoto3 from '../images/projects/cozinha-28-n/apt-template-3.jpg'
import projecPhoto4 from '../images/projects/cozinha-28-n/apt-template-4.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Cozinha',
  subTitle: '28n',
  typeService: 'PROJETO',
  duration: '04 meses',
  deliverDate: '2017',
  local: 'Brasília',
  color: '#7654cc',
  description: (
    <Fragment>
      <p>
        Neste projeto nós ousamos na escolha de materiais dessa cozinha.
        Utilizamos em todas as bancadas o <b>mármore carrara</b>, um material
        nobre, mas de porosidade considerável. Os clientes toparam a aventura,
        foi preciso recorrer a empresa especializada em Brasília para fazer a
        correta impermeabilização das pedras. Foi trabalhoso, mas o{' '}
        <b>resultado nos encanta</b>.
      </p>
    </Fragment>
  ),
  photosFirstRow: [
    { id: 'gap1', gap: true, className: 'dimensionGap' },
    { id: '01', image: projecPhoto1, className: 'dimensionOption1' },
    { id: '02', image: projecPhoto4, className: 'dimensionOption2' },
    { id: '03', image: projecPhoto3, className: 'dimensionOption3' },
    { id: '04', image: projecPhoto2, className: 'dimensionOption4' }
  ],
  nextProject: 'apartamento-213-n'
}

const IndexPage = () => (
  <Layout>
    <SEO title="Cozinha 28 N" keywords={[`gatsby`, `application`, `react`]} />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectGrid photosFirstRow={dynamicInfos.photosFirstRow} />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
